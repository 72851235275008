import "./matching.scss";

import React, { Fragment, useEffect, useState } from "react";

import { Alert } from "react-bootstrap";
import BodyBox from "../../layouts/bodyBox";
import LoadingOverlay from "../../components/LoadingOverlay";
import Quiz from "../../components/Quiz";
import SEO from "../../components/Seo";
import UserCollection from "../../components/UserCollection";
import axios from "axios";
import { navigate } from "gatsby";

const Matching = () => {
  // User related
  const [displayUserCollection, setDisplayUserCollection] = useState(false);

  // Quiz related
  const [loadingQuiz, setLoadingQuiz] = useState(true);
  const [displayQuiz, setDisplayQuiz] = useState(false);
  const [quizData, setQuizData] = useState({});
  const [quizResponses, setQuizResponses] = useState([]);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    axios({
      method: "get",

      url: `${process.env.API_ENDPOINT}/matching/getQuiz/1`,
      withCredentials: true,
    })
      .then((res) => {
        setQuizData(res.data);
        setDisplayQuiz(true);
      })
      .catch((err) => {
        setErrMsg("Error fetching questionaire");
        setErr(true);
        setDisplayQuiz(false);
      })
      .finally(() => {
        setLoadingQuiz(false);
      });
  }, []);

  const submitResponses = (choiceMap) => {
    setQuizResponses(Array.from(choiceMap));
    setDisplayQuiz(false);
    setDisplayUserCollection(true);
  };

  const submitMatching = async (userFirst, userLast, userEmail) => {
    try {
      const user = await axios({
        method: "post",
        data: {
          first_name: userFirst,
          last_name: userLast,
          email: userEmail,
        },
        url: `${process.env.API_ENDPOINT}/matching/updateOrCreateUser`,
        withCredentials: true,
      });
      const quizProps = await axios({
        method: "post",
        data: {
          user_id: user.data.user_id,
          quiz_id: quizData.quiz_id,
          choices: quizResponses,
        },

        url: `${process.env.API_ENDPOINT}/matching/submitQuiz`,
      });
      navigate(
        `/matching/results?user=${user.data.user_id}&attempt=${quizProps.data.attempt}`
      );
    } catch {
      setErrMsg("Error submitting quiz");
      setErr(true);
    }
  };

  return (
    <BodyBox>
      <SEO title="Get Matched" />
      {err ? <Alert variant="danger">{errMsg}</Alert> : null}
      <h1>Get Matched</h1>
      {loadingQuiz ? <LoadingOverlay message="Loading Questionaire" /> : null}
      {displayQuiz ? (
        <Quiz
          questions={quizData.questionstousers}
          submitResponses={submitResponses}
        />
      ) : null}
      {displayUserCollection ? (
        <div className="user-contact-form">
          <p className="">
            Final step! View your matches instantly after filling out the
            contact form.
          </p>
          <UserCollection handleUser={submitMatching} />
        </div>
      ) : null}
    </BodyBox>
  );
};

export default Matching;
