import "./UserCollection.scss";

import React, { useState } from "react";

import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const UserCollection = ({ handleUser }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    if (e.target.checkValidity()) {
      e.preventDefault();
      handleUser(firstName, lastName, email);
    }
  };

  return (
    <div className="user-collection">
      <h2 className="">Contact Information</h2>
      <form
        onSubmit={(e) => {
          return handleSubmit(e);
        }}
      >
        <label className="required" htmlFor="first-name">
          First Name:
        </label>
        <input
          type="text"
          id="first-name"
          onChange={(e) => {
            return setFirstName(e.target.value);
          }}
          required
        />
        <label htmlFor="last-name">Last Name:</label>
        <input
          type="text"
          id="last-name"
          onChange={(e) => {
            return setLastName(e.target.value);
          }}
        />
        <label className="required" htmlFor="email">
          Email:
        </label>
        <input
          type="email"
          id="email"
          onChange={(e) => {
            return setEmail(e.target.value);
          }}
          required
        />
        <Button type="submit" variant="primary">
          Submit
        </Button>
      </form>
    </div>
  );
};

UserCollection.propTypes = {
  handleUser: PropTypes.func,
};

UserCollection.defaultProps = {
  handleUser: () => {},
};

export default UserCollection;
